import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useLocation } from 'react-router-dom';

const AudioPlayer = forwardRef((props, ref) => {  // Ispravljen poziv funkcije forwardRef
  const audioRef = useRef(null);

  useImperativeHandle(ref, () => ({
    playMusic: () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    },
    pauseMusic: () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }
  }));

  /* const location = useLocation();

  useEffect(() => {
    const pagesToPauseMusic = ['']; 

    if (pagesToPauseMusic.includes(location.pathname)) {
      if (audioRef.current) {
        audioRef.current.pause(); 
      }
    } else {
      if (audioRef.current) {
        audioRef.current.play(); 
      }
    }
  }, [location.pathname]); */

  return (
    <audio
      ref={audioRef}
      src="BastionEPIC.mp3"
      preload="auto"
      loop
      style={{ display: 'none' }} 
    />
  );
});

export default AudioPlayer;
