import React from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import '../i18n.js';
import VideoBackground from "./VideoBackground.js";


export default function Main() {
  const { t } = useTranslation(); 

  return (
    <>
    <Helmet>
    <title>{t('Dobro došli u Airsoft klub Bosna')}</title>
    <link rel="canonical" href={`https://askbosna.ba`} />
        <meta name="description" content={t('Naš glavni cilj je druženje i pružanje značajnog doprinosa zajednici. Svi ljubitelji sporta su dobrodošli! Kroz udruženje želimo stimulisati mlade ljude da se bave sportom i međusobno druže')} />
        <meta name="keywords" content="Airsoft, Klub, Bosna, sport, askbosna, ASK Bosna" />
    </Helmet>
    <VideoBackground />
    <main className="main--main">
     {/* <div className="main--title">
        <div className="title--showup">{t('Dobro došli')}</div> 
        <div className="title--slidein">&nbsp;{t('u Airsoft klub')}</div> 
        <div className="title--showup">&nbsp;{t('Bosna')}</div> 
      </div>*/}
      <div className="main--mid">
        <div className="soc--links">
          <a href="https://www.facebook.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/facebook.svg" alt="fb-ico" className="soc--ico" /></a>
          <a href="https://instagram.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/instagram.svg" alt="in-ico" className="soc--ico" /></a>
          {/*<a href="https://tiktok.com" target="_blank" rel="noreferrer"><img src="/images/tiktok.svg" alt="tt-ico" className="soc--ico" /></a>*/}
          <a href="https://discord.gg/2UpEZ46z" target="_blank" rel="noreferrer"><img src="/images/discord.svg" alt="dc-ico" className="soc--ico" /></a>
        </div>
      </div>
    </main>
    </>
  );
}
