import React, { useRef, useEffect, useState } from "react";
import desktopVideo from "../videos/v4.2.webm"; 
import mobileVideo from "../videos/v4.2mob.webm"; 
import "./VideoBackground.css";

const VideoBackground = () => {
  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(window.innerWidth <= 768 ? mobileVideo : desktopVideo);

  useEffect(() => {
    // Funkcija za ažuriranje izvora videa na osnovu veličine ekrana
    const updateVideoSrc = () => {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;
      console.log("Resize detected, isMobile:", isMobile); // Debugging poruka
      setVideoSrc(isMobile ? mobileVideo : desktopVideo);
    };

    // Pokreni odmah prilikom učitavanja stranice
    updateVideoSrc();

    // Dodaj event listener za promene u veličini prozora
    window.addEventListener("resize", updateVideoSrc);

    // Ukloni event listener prilikom demontaže komponente
    return () => {
      window.removeEventListener("resize", updateVideoSrc);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handlePlay = () => {
      video.currentTime = 0.1; // Mali pomak za Safari
      video.play()
        .then(() => console.log("Video playing:", videoSrc)) // Debugging poruka
        .catch(e => console.log("Play error:", e));
    };

    // Pokreni odmah
    handlePlay();

    // Event listeneri za resume
    const events = ["click", "touchstart", "keydown"];
    const resumePlay = () => {
      handlePlay();
      events.forEach(e => document.removeEventListener(e, resumePlay));
    };

    events.forEach(e => document.addEventListener(e, resumePlay, { once: true }));

    // Cleanup
    return () => {
      events.forEach(e => document.removeEventListener(e, resumePlay));
    };
  }, [videoSrc]);

  return (
    <video
      ref={videoRef}
      key={videoSrc} // Ključ za forsiranje ponovnog rendera
      autoPlay
      loop
      muted
      playsInline
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: -1
      }}
    >
      <source src={videoSrc} type="video/mp4" />
    </video>
  );
};

export default VideoBackground;
