import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Navbar from './components/Navbar.js';
import Pocetna from './components/Pocetna.js';
import Obavjestenja from './components/Obavjestenja.js';
import Nastim from './components/Nastim.js';
import Onama from './components/Onama.js';
import Clanstvo from './components/Clanstvo.js';
import Donacija from './components/Donacija.js';
import Audio from './components/Audio.js';
import ScrollToTop from "./components/ScrollToTop";
import VideoBackground from './components/VideoBackground';

function App() {
  const backgroundMusicRef = React.useRef(null);

  const pauseMusic = () => {
    if (backgroundMusicRef.current) {
      backgroundMusicRef.current.pauseMusic();
    }
  };

  const playMusic = () => {
    if (backgroundMusicRef.current) {
      backgroundMusicRef.current.playMusic();
    }
  };

  function BackgroundWrapper({children}) {
    const location = useLocation();

    useEffect(() => {
      // Pauziraj sve videe pri promeni rute
      document.querySelectorAll('video').forEach(video => video.pause());
      playMusic();
    }, [location.pathname]);

    const getBackgroundClass = () => {
      switch (location.pathname) {
        case '/': return 'pocetna--background';
        case '/obavjestenja': return 'obavjestenja--background';
        case '/nas-tim': return 'nastim--background';
        case '/postani-clan': return 'clanstvo--background';
        case '/o-nama': return 'onama--background';
        case '/sponzori': return 'donacija--background';
        default: return '';
      }
    }

    return (
      <div className={getBackgroundClass()}>
        {/* VideoBackground se renderuje samo na homepage-u */}
        {location.pathname === '/' && <VideoBackground key={location.key} />}
        {children}
      </div>
    );
  }

  return (
    <Router>
      <Audio ref={backgroundMusicRef} />
      <ScrollToTop />
      <BackgroundWrapper>
        <Navbar />
        <Routes>
          <Route path="/" element={<Pocetna />} />
          <Route path="/obavjestenja" element={<Obavjestenja />} />
          <Route path="/nas-tim" element={<Nastim />} />
          <Route path="/postani-clan" element={<Clanstvo />} />
          <Route path="/o-nama" element={<Onama pauseMusic={pauseMusic} playMusic={playMusic}/>} />
          <Route path="/sponzori" element={<Donacija />} />
        </Routes>
      </BackgroundWrapper>
    </Router>
  );
}

export default App;